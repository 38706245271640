import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Button } from 'react-bootstrap';
import CopyButton from './CopyButton';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import SymbolBubble from './SymbolBubble';
import ConfirmationModal from './ConfirmationModal';
import MicroS3Uploader from './microS3Uploader';


const DetailView = (props) => {
    /*
    * A component for displaying the details of an item.
    *
    * @param {str} primary_key - The primary key of the item.
    * @param {func} get_method - The method for getting the item.
    * @param {array} attributes - The attributes of the item.
    * Each object should have the following keys:
    *  - key: The key of the attribute in the item dictionary.
    *  - label: The label to display for the attribute.
    *  - type: The type of the attribute. Can be one of the following:
    *    - string: A string value.
    *    - boolean: A boolean value.
    *    - copy: A copyable string value.
    *    - link: A link to another page.
    *    - action: A button that performs an action.
    *    - render: A custom render function.
    * @param {func} update_method - The method for updating the item.
    * @param {func} delete_method - The method for deleting the item.
    * @param {str} title - The title of the item.
    * @returns {JSX} A detail view component.
    */

    const prestructureData = (attributes) => {
        let structure = {}
        attributes.forEach((attr) => {
            if (attr.key) {
                let key_parts = attr.key.split('.');
                for (let i=0; i < key_parts.length - 1; i++) {
                    structure[key_parts[i]] = {};
                }
                structure[key_parts[key_parts.length - 1]] = "";
            }
        });
    }

    const [data, setData] = useState(prestructureData(props.attributes));
    const [updateData, setUpdateData] = useState({}); // for updating data
    const [response, setResponse] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let deleteMessage = (
        `Are you sure you want to delete this item?` +
        `\n\nID: ${data ? data.id : null}` +
        `\n\nNOTE: This action may not be reversible.`
    )

    const handleDataChange = (event) => {
        if (event.target.value === "" || event.target.value === null) {
            delete updateData[event.target.getAttribute('attrkey')];
        } else {
            let key = event.target.getAttribute('attrkey');
            let value = event.target.value;

            if (props.attributes.find((attr) => attr.key === key).type === 'boolean') {
                value = event.target.checked;
            }

            console.log(key, value);

            setData({...data, [key]: value});
            setUpdateData({...updateData, [key]: value});
        }
    }

    const handleDelete = () => {
        props.delete_method(props.primary_key).then((response) => {
            loadData();
            setResponse(["Item deleted."]);
        });
    }

    const handleUpdate = () => {
        props.update_method(props.primary_key, updateData).then((response) => {
            if (response) {
                setUpdateData({});
                setResponse(["Item updated."]);
            } else {
                setResponse([
                    "There was an error updating the item."
                ]);
            }
        });
    }

    const extractFromAttribute = (key, item) => {
        /*
        * Extracts a value from an attribute key.
        *
        * @param {str} key - The attribute key.
        * @returns {str} key - The extracted value.
        */
        let key_parts = key.split('.');
        let value = item;
        for (const key_part of key_parts) {
            value = value[key_part];
        }
        return value;
    }

    const makeDetailLink = (detail_link, item) => {
        /*
        * Replaces the :key placeholders in the detail_link with the
        * corresponding values from the item.
        * 
        * @param {str} detail_link - The detail link string.
        * @param {dict} item - The item to use for replacement.
        * @returns {str} detail_link - The updated detail link string.
        */
        for (const [key, value] of Object.entries(item)) {
            detail_link = detail_link.replace(`<${key}>`, value);
        }
        return detail_link;
    }

    const loadData = () => {
        props.get_method(props.primary_key).then((data) => {
            setData(data);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Panel>
            <h3>{props.title}</h3>
            {data && props.attributes.map((attr) => (
                <Item key={attr.key} attrCount={2}>
                    <label>
                        {attr.label}
                        {attr.tooltip !== undefined ? (
                            <SymbolBubble
                                variant={attr.tooltip.variant ? attr.tooltip.variant : "info"}
                                text={attr.tooltip.text} />
                        ) : (null)}
                    </label>
                    {attr.settable ? (
                        attr.type === 'boolean' ? (
                            <Form.Check type="checkbox" attrkey={attr.key} onChange={handleDataChange} checked={!!extractFromAttribute(attr.key, data)} value={true} />
                        ) : attr.type === "choice" ? (
                            <select attrkey={attr.key} onChange={handleDataChange}>
                                {attr.allow_unset ? (
                                    <option value>--</option>
                                ) : (null)}
                                {attr.choices.map((choice) => (
                                    (choice.value === extractFromAttribute(attr.key, data) ? (
                                        <option value={choice.value} selected>{choice.label}</option>
                                    ) : (
                                        <option value={choice.value}>{choice.label}</option>
                                    ))
                                ))}
                            </select>
                        ) : attr.type === 'text' ? (
                            <textarea type="text" attrkey={attr.key} onChange={handleDataChange} value={extractFromAttribute(attr.key, data)} />
                        ) : (
                            <input type="text" attrkey={attr.key} onChange={handleDataChange} value={extractFromAttribute(attr.key, data)} />
                        )
                    ) : (
                        attr.type === 'boolean' ? (
                            <span>{extractFromAttribute(attr.key, data) ? "Yes" : "No"}</span>
                        ) : attr.type === "copy" ? (
                            <IdDisplay>{extractFromAttribute(attr.key, data)} <CopyButton value={extractFromAttribute(attr.key, data)} /></IdDisplay>
                        ) : attr.type === "link" && attr.detailLink !== undefined ? (
                            <StyledLink onClick={() => window.location.href = makeDetailLink(attr.detailLink, data) }>{attr.label}</StyledLink>
                        ) : attr.type === "external_link" ? (
                            <StyledLink onClick={() => window.open(data[attr.key], '_blank') }>{attr.label}</StyledLink>
                        ) : attr.type === "upload" ? (
                            <MicroS3Uploader presignedUrl={data[attr.key]} />
                        ) : attr.type === "action" ? (
                            <Button
                                variant={attr.variant}
                                size="sm"
                                onClick={() => attr.onClick(data)}
                                {...attr.isDisabled ? {disabled: attr.isDisabled(data)} : false}
                            >
                                    {attr.label}
                            </Button>
                        ) : attr.type === "render" ? (
                            attr.render_function(data)
                        ) : (
                            <span>{extractFromAttribute(attr.key, data)}</span>
                        )
                    )}
                </Item>
            ))}
            <div>
                {props.update_method ? (
                    <Button variant="success" onClick={handleUpdate}>Update</Button>
                ) : (null)}
                {props.delete_method ? (
                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                ) : (null)}
                <span>
                    {response.map((item, idx) => (
                        <p key={idx}>{item}</p>
                    ))}
                </span>
            </div>

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm delete?"
                message={deleteMessage}
                onConfirm={() => {handleDelete()}}
                onHide={() => {setShowDeleteModal(false)}}
                signatureRequired={true}
                signatureMessage='Please type "delete" to confirm.'
                signatureChallenge='delete' />

        </Panel>
    )
}

DetailView.propTypes = {
    primary_key: PropTypes.string.isRequired,
    get_method: PropTypes.func.isRequired,
    attributes: PropTypes.array.isRequired,
    update_method: PropTypes.func,
    delete_method: PropTypes.func,
    title: PropTypes.string.isRequired,
}

DetailView.defaultProps = {

}

export default DetailView;

const Panel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.6);
    width: 95%;
    margin: 5px 0px 0px 0px;
`;

const Item = styled.div`
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.attrCount}, minmax(175px, 1fr))`};
    grid-gap: 0px;
    justify-items: left;
    align-items: left;
    background-color: rgba(200, 200, 200, 0.6);
    width: 100%;
    padding: 3px 20px;
    vertical-align: middle;

    &:hover {
        background-color: rgba(200, 200, 200, 0.8);
    }
`;

const StyledLink = styled(Link)`
    color: blue !important;
    text-decoration: underline !important;
`;

const IdDisplay = styled.span`
    font-size: 0.8em;
    color: grey;
    background-color: white;
    padding: 3px;
    border-radius: 3px;
`;
