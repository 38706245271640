// Desc: Video utility functions
export function getVideoFrame(file, time) {
    return new Promise((resolve, reject) => {
        const src = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.setAttribute('crossOrigin', 'anonymous');
        video.src = src;
        video.muted = true;

        video.onloadedmetadata = () => {
            video.currentTime = time;
        }

        video.onseeked = () => {
            // video.oncanplay = () => {
            setTimeout(() => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const dataURL = canvas.toDataURL();
                video.remove();
                URL.revokeObjectURL(src);
                resolve(dataURL);
            }
            , 500);
        }
    });
}

export function getCurrentVideoFrame(playerRef, callback) {
    const video = playerRef.current.el().querySelector('video');
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 450;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL();
    callback(dataURL);
}

export async function getVideoMetadata(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
            resolve(video);
        }
    })
}

export async function getVideoInfo(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
            video.currentTime = Math.min(1, video.duration/2);
        };

        video.onseeked = () => {
            setTimeout(() => {
            const canvas = document.createElement('canvas');
            canvas.width = 312;
            canvas.height = 176;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL();
            const bitrate = (file.size * 8) / video.duration;
            const camera = checkFilenameFormat(file.name) ? 'ORCHID_SL' : 'UKNOWN';
            video.remove();
            URL.revokeObjectURL(video.src);
            resolve({ width: video.videoWidth, height: video.videoHeight, bitrate, duration: video.duration, thumbnail: dataURL, camera });
            }
            , 100);
        };

        video.onerror = (e) => {
            reject(e);
        };
    });
}

//a function to check if a file has the YYYYMMDD_HHMMSS.mp4 format
export function checkFilenameFormat (filename) {
    if (filename.length !== 19) {
        return false;
    }
    if (filename.slice(4,5) !== '0' && filename.slice(4,5) !== '1') {
        return false;
    }
    return true;
}

export async function combineMultipartVideoData (files) {
    //this function 
    //(1) determines if any files in the file list were created contiuously and 
    //(2) groups them by date

    //if filenames follow the YYYYMMDD_HHMMSS.mp4 format
    //then the files can be sorted by date
    //and the files that were created continuously can be grouped together
    if (files.length === 0) {
        return [];
    }
    if (files.some(file => !checkFilenameFormat(file.name))) {
        console.log('Filenames are not in the correct format for multipart uploads.')
        return files.map(file => [file]);
    }

    //get creation timestamps from filenames
    //the filename format is YYYYMMDD_HHMMSS.mp4
    //and the timestamps should be Date objects
    let timestamps = [];
    for (let idx = 0; idx < files.length; idx++) {
        let filename = files[idx].name;
        let timestamp = new Date(filename.slice(0,4), filename.slice(4,6), filename.slice(6,8), filename.slice(9,11), filename.slice(11,13), filename.slice(13,15));
        //go back one month, because months are zero-index in javascript (d'oh!)
        timestamp.setMonth(timestamp.getMonth() - 1);
        timestamps.push(timestamp);
    }

    //sort the files by date
    files.sort((a, b) => {
        return timestamps[files.indexOf(a)] - timestamps[files.indexOf(b)];
    });
    timestamps.sort((a, b) => { 
        return a - b;
    });

    //load the metadata from each mp4 file and store the length of the video
    let videoLengths = [];
    for (let idx = 0; idx < files.length; idx++) {
        let videoLength = 0;
        let metadata = await getVideoMetadata(files[idx]);
        videoLength = metadata.duration;
        videoLengths.push(videoLength);
        metadata.remove();
    };

    //find the files that were created continuously
    let continuousFiles = [];
    let fileCounter = 0;
    for (let idx = 0; idx < files.length; idx++) {
        //if this is the first file, add it to the list
        if (idx === 0) {
            continuousFiles.push([files[idx]]);
        } else {
            //if the time between this file and the previous file is less than the length of the previous file,
            // add it to the list
            if (timestamps[idx] - timestamps[idx - 1] < (videoLengths[idx - 1] * 1000 + 10*60*1000)) {
                continuousFiles[fileCounter].push(files[idx]);
            } else {
                //otherwise, add the current list to the list of continuous files and start a new list
                fileCounter++;
                continuousFiles.push([files[idx]]);
            }
        }
    }
    return continuousFiles;
}

export function sortVideoFilesByName (files) {

    //determine if the filenames are in orchid camera format
    //if they are, sort them by date

    if (!files.some(file => !checkFilenameFormat(file.name))) {
        console.log('sorting files')
        //get creation timestamps from filenames
        //the filename format is YYYYMMDD_HHMMSS.mp4
        //and the timestamps should be Date objects
        let timestamps = [];
        for (let idx = 0; idx < files.length; idx++) {
            let filename = files[idx].name;
            let timestamp = new Date(filename.slice(0,4), filename.slice(4,6), filename.slice(6,8), filename.slice(9,11), filename.slice(11,13), filename.slice(13,15));
            //go back one month, because months are zero-index in javascript (d'oh!)
            timestamp.setMonth(timestamp.getMonth() - 1);
            timestamps.push(timestamp);
        }

        //sort the files by date
        files.sort((a, b) => {
            return timestamps[files.indexOf(a)] - timestamps[files.indexOf(b)];
        });

    }
    return files;
}