import styled from 'styled-components';
import ListView from '../components/ListView.js';
import NewEntry from './NewEntry.js';
import { createAgreementAdmin, getAgreementsAdmin } from '../services/api/admin_agreements.js';


const AdminAgreements = (props) => {
    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "name",
            "label": "Name",
            "type": "string",
            "filterable": true,
            "detailLink": "/admin/agreements/<id>",
            "settable": true,
            "required": true,
        },
        {
            "key": "description",
            "label": "Description",
            "type": "text",
            "filterable": false,
            "settable": true,
            "required": false,
        },
        {
            "key": "required",
            "label": "Required?",
            "type": "boolean",
            "filterable": true,
            "settable": true,
            "tooltip": {
                "variant": "warning",
                "text": "If required, users must agree to this agreement before they can access the group/organization.",
            }
        },
        {
            "key": "tier",
            "label": "User Account Tier",
            "type": "choice",
            "settable": true,
            "allow_unset": true,
            "choices": [
                {
                    "value": "free",
                    "label": "Free",
                },
                {
                    "value": "pro",
                    "label": "Professional",
                },
            ]
        },
        {
            "key": "group_id",
            "label": "Group ID",
            "type": "string",
            "filterable": true,
            "settable": true,
            "required": false,
        },
        {
            "key": "organization_id",
            "label": "Organization ID",
            "type": "string",
            "filterable": true,
            "settable": true,
            "required": false,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "filterable": true,
            "settable": false,
        }
    ];

    return (
        <Page>
            <ListView
                title="Admin: Agreements"
                list_method={getAgreementsAdmin}
                attributes={attributes}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="Admin: Agreements"
                create_method={createAgreementAdmin}
                attributes={attributes} />
        </Page>
    )
}

export default AdminAgreements;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
